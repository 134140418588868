<template>
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb_area" v-if="type === 'area' && page !== 'search'">
      <div class="container">
        <ol class="arrows">
          <li>
            <a v-if="isLanding" href="/">{{ $t("product-page.main") }}</a>
            <router-link v-else to="/">{{ $t("product-page.main") }}</router-link>
          </li>
          <li>
            <a v-if="isLanding" :href="searchLink">{{ data.text }}</a>
            <router-link v-else :to="searchLink">{{ data.text }}</router-link>
          </li>
          <li>
            <a href="#" onclick="return false;">{{ data.dest }}</a>
          </li>
          <li>{{ data.stage }}</li>
        </ol>
      </div>
    </div>
    <div class="breadcrumb_area" v-if="type === 'area' && page === 'search' && this.$route.fullPath !== '/' && !this.$route.fullPath.includes('dealType')">
      <div class="container">
        <ol class="arrows" v-if="destination === ''">
        </ol>
        <ol class="arrows" v-else-if="destination !== ''">
          <li class="arrows_li first" v-if="destination.destSnd !== ''">
            <a v-if="isLanding" href="/">
              {{$t("hotel-page.home")}}
            </a>
            <router-link v-else to="/">
              {{$t("hotel-page.home")}}
            </router-link>
          </li>
          <li class="arrows_li" v-if="destination.destSnd !== ''">
            <a v-if="isLanding" :href="searchLink">
              <span v-html="destination.destSnd"></span>
            </a>
            <router-link v-else :to="searchLink">
              <span v-html="destination.destSnd"></span>
            </router-link>
          </li>
          <li class="arrows_li" v-if="destination.destThird !== ''">
            <a>
              <span v-html="destination.destThird"></span>
            </a>
          </li>
        </ol>
      </div>
    </div>
    <div :class="['breadcrumb', 'flat', stage >= 3 ? 'active' : '']" v-if="type === 'flat'">
      <a href="#" :class="{ active: stage >= 1 }" onclick="return false;">{{ $t("product-page.vacation-package-details")}}</a>
      <a href="#" :class="{ active: stage >= 2 }" onclick="return false;">{{ $t("product-page.payment-details") }} - {{ $t("product-page.safe-purchase") }}</a>
      <a href="#" :class="{ active: stage >= 3 }" onclick="return false;">{{ $t("product-page.order-completion") }}</a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    type: {
      type: String,
      default: 'area',
    },
    destination: {
      type: Object,
      default: null,
    },
    page: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    stage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters({
      isLanding: 'GET_IS_LANDING_PAGE',
    }),
    searchLink() {
      if (this.page !== 'search') {
        const queryBody = localStorage.getItem('search-query') ? localStorage.getItem('search-query') : '#';
        if (queryBody === '#') return '#';
        const jsonBody = JSON.parse(queryBody);
        return `/search-result?${Object.entries(jsonBody).map(([k, v]) => (v ? `${k}=${encodeURIComponent(v)}` : k)).join('&')}`;
      } else {
        const landingUrl = localStorage.getItem('landing-url') ? localStorage.getItem('landing-url') : '';
        const ptnCity = /city/i;
        const ptnDestination = /destination/i;
        const ptnChain = /chain/i;
        const resultCity = landingUrl.match(ptnCity);
        const resultDestination = landingUrl.match(ptnDestination);
        const resultChain = landingUrl.match(ptnChain);
        let newUrl = '';
        if (resultCity !== null) {
          newUrl = `/hotels-page/?city=${landingUrl.split('=')[1]}`;
        } else if (resultDestination !== null) {
          newUrl = `/?destination=${landingUrl.split('=')[1]}`;
        } else if (resultChain !== null) {
          newUrl = `/?chain=${landingUrl.split('=')[1]}`;
        }

        return newUrl;
      }
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  display: inline-block;
  overflow: hidden;
  border-radius: 0;
  counter-reset: flag;
  width: 100%;
  text-align: left;
  padding: 0;
  margin-top: 15px;
  background-color: #949494;
}
</style>
